<template>
  <div v-if="matrixBlocks.length" ref="lp_block">
    <div v-if="entryType !== 'landingPage2023'">
      <div
        class="lp__block"
        :class="blockClasses(block, blockIndex)"
        :style="blockStyles(block)"
        v-for="(block, blockIndex) in matrixBlocks"
        :key="blockIndex"
      >
        <Overlay class="c_hero_banner__overlay" ref="videoBannerOverlay" theme="full" :show-close-button="true">
          <div class="c_box--pos--relative c_hero_banner__overlay__video">
            <MediaVideo loading="eager" :source="videoSource" :video-id="videoId"></MediaVideo>
          </div>
        </Overlay>
        <MediaImage
          class="lp__block__background"
          background
          :alt="block.backgroundImage[0].title"
          :sources="[
            {
              media: '(min-width: 1024px)',
              srcset: block.backgroundImage[0].lpBackgroundWide.srcsetWebp,
              type: 'image/webp',
            },
            { media: '(min-width: 1024px)', srcset: block.backgroundImage[0].lpBackgroundWide.srcset },
            { srcset: block.backgroundImage[0].lpBackgroundSquare.srcsetWebp, type: 'image/webp' },
            { srcset: block.backgroundImage[0].lpBackgroundSquare.srcset },
          ]"
          v-if="block.typeHandle === 'pageHeader' && block.backgroundImage.length"
        />
        <div
          class="lp__block--pageHeader__wrapper c_wrapper"
          :class="[
            `c_text--${block.textAlignment}`,
            { 'lp__block--pageHeader--background': block.backgroundImage.length },
          ]"
          v-if="block.typeHandle === 'pageHeader'"
        >
          <h1 class="lp__block--pageHeader__header c_header--8 c_text--graphik_bold" v-html="block.header"></h1>
          <p class="lp__block--pageHeader__subheader c_header--7 c_text--graphik_bold" v-html="block.subheader"></p>
          <div class="lp__block--pageHeader__buttons c_buttons" v-if="block.pageHeaderButton.length > 0">
            <LinkField
              v-if="parseLinkField(block.pageHeaderButton[0], 'superTable').valid"
              :button-attrs="{
                outline: false,
                color: 'red',
              }"
              :super-table-field="block.pageHeaderButton[0]"
            />
            <LinkField
              v-if="parseLinkField(block.pageHeaderButton[1], 'superTable').valid"
              :button-attrs="{
                outline: false,
                color: 'red',
              }"
              :super-table-field="block.pageHeaderButton[1]"
            />
          </div>
        </div>
        <section
          class="c_wrapper"
          :class="{
            c_grid: block.columns !== '1',
            'lp__block--basicText--2_column': block.columns === '2',
            'lp__block--basicText--3_column': block.columns === '3',
            'c_grid--2': block.columns === '2',
            'c_grid--2-3': block.columns === '3',
          }"
          v-else-if="block.typeHandle === 'basicText'"
        >
          <LPText
            :button-link-field="parseLinkField(item, 'superTable').valid ? item : null"
            :eyebrow="item.eyebrow || null"
            :eyebrow-style="block.columns !== '1' ? 'regular' : null"
            :subheader="item.subheader || null"
            :text="item.text || null"
            :text-alignment="item.textAlignment || null"
            :primary-cta-btn="item.primaryCtaBtn || null"
            :primary-cta-btn-label="item.primaryCtaBtnLabel || null"
            :primary-cta-btn-aria-label="item.primaryCtaBtnAriaLabel || null"
            :primary-cta-btn-theme="item.primaryCtaBtnTheme || null"
            :primary-cta-btn-open-in-a-new-window="item.primaryCtaBtnOpenInANewWindow || null"
            v-for="(item, index) in block.textBlocks"
            :key="index"
          />
        </section>
        <section class="lp__block--textImage__wrapper c_wrapper" v-else-if="block.typeHandle === 'textImage'">
          <div v-if="['imageBackgroundFadeTextBox'].includes(block.layout)"></div>
          <div
            v-if="block.image && block.image.length"
            class="c_box--full--height"
            :class="{
              'c_box--mask': ['textBoxImageBackgroundFade', 'imageBackgroundFadeTextBox'].includes(block.layout),
            }"
          >
            <div
              v-if="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId"
              :is="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId ? 'Button' : 'div'"
              :unstyle="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId ? true : false"
              :reset="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId ? true : false"
              @onClick="
                ['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId
                  ? textImageVideoClick()
                  : false
              "
              :class="{
                'lp__block--textImage__button':
                  ['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId ? true : false,
              }"
            >
              <MediaImage
                class="lp__block--textImage__image"
                :alt="block.image[0].title"
                :background="
                  [
                    'textLargeImage',
                    'largeImageText',
                    'textBoxImageBackground',
                    'imageBackgroundTextBox',
                    'textBoxImageBackgroundFade',
                    'imageBackgroundFadeTextBox',
                    'textImageCard',
                    'imageTextCard',
                  ].includes(block.layout)
                "
                :sources="textImageSources(block)"
              />
              <Flex
                v-if="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId"
                center
                class="c_box--mask c_box--pos--absolute lp__block--textImage__image__play_icon"
              >
                <IconSVG
                  handle="media_play"
                  width="60"
                  height="60"
                  :replacements="{ 'Media - Play': $t('Media - Play') }"
                />
              </Flex>
              <div
                v-if="['textImage', 'imageText'].includes(block.layout) && block.image[0].videoId"
                class="c_box--mask c_box--pos--absolute lp__block--textImage__image__mask"
              ></div>
            </div>
            <MediaImage
              v-else
              class="lp__block--textImage__image"
              :alt="block.image[0].title"
              :background="
                [
                  'textLargeImage',
                  'largeImageText',
                  'textBoxImageBackground',
                  'imageBackgroundTextBox',
                  'textBoxImageBackgroundFade',
                  'imageBackgroundFadeTextBox',
                  'textImageCard',
                  'imageTextCard',
                ].includes(block.layout) ||
                (['textImage', 'imageText'].includes(block.layout) && ['sm', 'md'].includes($mq))
              "
              :sources="textImageSources(block)"
            />
          </div>
          <div
            class="lp__block--textImage__fade c_box--mask"
            v-if="['textBoxImageBackgroundFade', 'imageBackgroundFadeTextBox'].includes(block.layout)"
            :style="{ '--backgroundOpacity': block.backgroundOpacity }"
          ></div>
          <LPText
            class="lp__block--textImage__text"
            :button-link-field="
              parseLinkField(block.textImageButton[0], 'superTable').valid && block.textImageButton
                ? block.textImageButton[0]
                : null
            "
            :button-two-link-field="
              parseLinkField(block.textImageButton[1], 'superTable').valid && block.textImageButton[1]
                ? block.textImageButton[1]
                : null
            "
            :button-outline="['textBoxImageBackgroundFade', 'imageBackgroundFadeTextBox'].includes(block.layout)"
            :eyebrow="block.eyebrow || null"
            :subheader="block.subheader || null"
            :text="block.text || null"
            :text-alignment="block.textAlignment || null"
          />
          <!-- <div
          class="lp__block--textImage__buttons c_buttons c_buttons--center"
          v-if="
            ['textImageCard', 'imageTextCard'].includes(block.layout) &&
              block.textImageButton.length &&
              parseLinkField(block.textImageButton[0], 'superTable').valid
          "
        >
          <LinkField
            :button-attrs="{
              outline: true,
              color: 'black',
            }"
            :super-table-field="block.textImageButton[0]"
          />
        </div> -->
          <Overlay
            v-if="
              block.image &&
              block.image.length &&
              ['textImage', 'imageText'].includes(block.layout) &&
              block.image[0].videoId
            "
            width="auto"
            height="auto"
            class="lp__block--textImage__overlay"
            ref="textImageOverlay"
            :show-close-button="true"
          >
            <div class="c_box--pos--relative lp__block--textImage__overlay__video">
              <MediaVideo
                v-if="block.image[0].videoId"
                :video-id="block.image[0].videoId"
                :title="block.image[0].title ? block.image[0].title : ''"
                :source="block.image[0].videoSource ? block.image[0].videoSource : 'youtube'"
              />
            </div>
          </Overlay>
        </section>
        <section
          class="lp__block--contentIconCards__wrapper c_wrapper"
          :class="[
            'lp__block--contentIconCards__wrapper--layout--' + block.layout,
            block.layout !== 'contentTop' ? 'lp__block--contentIconCards__wrapper--layout--horizontal' : '',
          ]"
          v-else-if="block.typeHandle === 'contentIconCards'"
        >
          <LPText
            class="lp__block--contentIconCards__content"
            :eyebrow="block.eyebrow"
            :subheader="block.header"
            :text="block.text"
            :button-link-field="block.contentIconCardsButton.length ? block.contentIconCardsButton[0] : null"
            :text-alignment="block.layout === 'contentTop' ? 'center' : $mq === 'lg' ? 'left' : 'center'"
            v-if="block.eyebrow || block.header || block.text || block.layout !== 'contentTop'"
          ></LPText>
          <div
            class="lp__block--contentIconCards__grid"
            :class="[
              'lp__block--contentIconCards__grid--count--' + block.iconCards.length,
              iconCardHasText(block.iconCards) ? 'lp__block--contentIconCards__grid--has_text' : '',
              iconCardHasButton(block.iconCards) ? 'lp__block--contentIconCards__grid--has_button' : '',
            ]"
          >
            <LPIconText
              class="lp__block--contentIconCards__grid__item"
              :bg-color="
                block.layout !== 'contentTop' && block.colors !== 'blackTextYellowBackground' ? 'yellow' : 'white'
              "
              :icon-src="iconCard.icon[0].url"
              :icon-alt="iconCard.icon[0].title"
              :header="iconCard.header"
              :text="iconCard.iconText"
              :link-field="iconCard"
              :border-shadow="block.iconCardBorder"
              v-for="(iconCard, cardIndex) in block.iconCards"
              :key="cardIndex"
              v-if="iconCard.icon.length"
            >
            </LPIconText>
          </div>
        </section>
        <section class="lp__block--textImageGrid__wrapper c_wrapper" v-else-if="block.typeHandle === 'textImageGrid'">
          <LPTextImageGrid
            :button-link-field="block.textImageGridButton.length ? block.textImageGridButton[0] : null"
            :eyebrow="block.eyebrow"
            :header="block.header"
            :text="block.text"
            :text-alignment="block.textAlignment"
            :image-layout="block.imageLayout"
            :image-optimized-field="
              ['masonry'].includes(block.style)
                ? 'lpImageGridMasonry'
                : ['fullWidthGrid', 'horizontal'].includes(block.style)
                ? 'lpImageGridSquare'
                : 'lpImageGridLandscape'
            "
            :images="block.images"
            :display-style="block.style"
          >
          </LPTextImageGrid>
        </section>
        <section class="lp__block--gallery__wrapper c_wrapper" v-else-if="block.typeHandle === 'gallery'">
          <Gallery
            :arrows="block.slideIndicators != 'dashesOnly' ? true : false"
            :indicators="block.slideIndicators != 'arrowsOnly' ? true : false"
            :header="block.header"
            :grid-gap="block.noGap ? '0px' : '17px'"
            :text="block.text"
            :link-field="block.galleryButton.length ? block.galleryButton[0] : null"
            :overlay="block.overlayContent ? true : false"
            :layout="block.slideLayout"
            :slides="formatGallerySlides(block)"
          />
        </section>
        <section class="lp__block--galleryGrid__wrapper c_wrapper" v-else-if="block.typeHandle === 'galleryGrid'">
          <GalleryGrid
            :images="galleryGridSources(block.images)"
            :slide-layout="block.layout"
            :arrow-color="block.colors == 'whiteTextBlackBackground' ? 'white' : 'black'"
          />
        </section>
        <section class="lp__block--quoteSlider__wrapper c_wrapper" v-else-if="block.typeHandle === 'quoteSlider'">
          <QuoteSlider :text-alignment="block.textAlignment" :slides="quoteSliderSlides(block.slides)"> </QuoteSlider>
        </section>
        <section
          class="lp__block--embedSoundcloud__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'embedSoundcloud'"
        >
          <SoundCloud
            :embed-height="block.height"
            :embed-width="block.width"
            :podcast-href="block.podcastHref"
            :podcast-name="block.podcastName"
            :track-href="block.trackHref"
            :track-id="block.trackId"
            :track-title="block.trackTitle"
            v-if="block.podcastHref && block.podcastName && block.trackHref && block.trackId && block.trackTitle"
          />
        </section>
        <section
          class="lp__block--textContentCards__wrapper c_wrapper c_wrapper--950"
          v-else-if="block.typeHandle === 'textContentCards'"
        >
          <LPTextCardSlider
            :eyebrow="block.eyebrow"
            :header="block.header"
            :text="block.text"
            :button-link-field="block.textContentCardsButton.length ? block.textContentCardsButton[0] : null"
            :card-type="block.cardType"
            :cards="formatTextContentCards(block.cards, block.cardType)"
            :text-alignment="block.textAlignment"
          >
          </LPTextCardSlider>
        </section>
        <section class="c_wrapper c_grid c_grid--2" v-else-if="block.typeHandle === 'textAccordion'">
          <LPText
            :button-link-field="block.textAccordionButton.length ? block.textAccordionButton[0] : null"
            :eyebrow="block.eyebrow || null"
            :subheader="block.subheader || null"
            :text="block.text || null"
            :text-alignment="block.textAlignment || null"
          />
          <Accordion first-tab-open class="c_text--left" v-if="block.accordion.length">
            <AccordionTab class="lp__block--textAccordion__tab" v-for="item in block.accordion" :key="item.header">
              <div slot="header">
                <div class="lp__block--textAccordion__header">
                  <IconSVG
                    class="lp__block--textAccordion__header__icon lp__block--textAccordion__header__icon--plus"
                    handle="plus"
                    height="12"
                    width="12"
                    aria-hidden="true"
                  />
                  <IconSVG
                    class="lp__block--textAccordion__header__icon lp__block--textAccordion__header__icon--minus"
                    handle="minus"
                    height="2"
                    width="12"
                    aria-hidden="true"
                  />
                  <span class="lp__block--textAccordion__header__label" v-html="item.header"></span>
                </div>
              </div>
              <div slot="content">
                <div class="lp__block--textAccordion__content c_lp_text__text c_text" v-html="item.text"></div>
              </div>
            </AccordionTab>
          </Accordion>
        </section>
        <section class="lp__block--videoPlayer__wrapper c_wrapper" v-else-if="block.typeHandle === 'videoPlayer'">
          <GalleryVideo
            :arrow-color="block.colors === 'whiteTextBlackBackground' ? 'white' : 'black'"
            :tabs="block.tabs"
          ></GalleryVideo>
        </section>

        <section
          class="lp__block--galleryHorizontal__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'galleryHorizontal'"
        >
          <GalleryHorizontal
            class="c_box--break_out"
            :cards="formatGalleryHorizontalCards(block.cards)"
          ></GalleryHorizontal>
        </section>
        <section class="lp__block--textIconList__wrapper c_wrapper" v-else-if="block.typeHandle === 'textIconList'">
          <LPTextIconList
            :eyebrow="block.eyebrow"
            :header="block.header"
            :text="block.text"
            :columns="parseInt(block.textColumns)"
            :icons="block.icons"
          ></LPTextIconList>
        </section>
        <section class="lp__block--textList__wrapper c_wrapper" v-else-if="block.typeHandle === 'textList'">
          <LPTextList
            :eyebrow="block.eyebrow"
            :header="block.header"
            :text="block.text"
            :image="
              block.image[0]
                ? {
                    title: block.image[0].title,
                    caption: block.image[0].caption,
                    sources: [
                      { srcset: block.image[0].lpImageTextUncropped.srcsetWebp, type: 'image/webp' },
                      { srcset: block.image[0].lpImageTextUncropped.srcset },
                    ],
                  }
                : null
            "
            :layout="block.layout"
            :list="block.list"
          ></LPTextList>
        </section>
        <section
          class="lp__block--featuredCardCards__wrapper c_wrapper c_wrapper--950"
          v-else-if="block.typeHandle === 'featuredCardCards'"
        >
          <LPTripCards
            v-if="block.header || block.text"
            :alignment="block.alignment"
            :header="block.header"
            :text="block.text"
            :button-link-field-parsed="block.featureCardCardsButton.length ? block.featureCardCardsButton[0] : null"
            :image="{
              title: block.image[0] ? block.image[0].title : null,
              url: block.image[0] ? block.image[0].url : null,
              sources: block.image[0]
                ? [
                    { srcset: block.image[0].lpBackgroundSquare.srcsetWebp, type: 'image/webp' },
                    { srcset: block.image[0].lpBackgroundSquare.srcset },
                  ]
                : null,
            }"
            :secondary-cards="formatSecondaryCards(block.secondaryCards)"
          >
          </LPTripCards>
        </section>
        <section
          class="lp__block--featuredCardCards__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'imageTextTabs'"
        >
          <LPImageTextTabs :tabs="block.tabs" :image-fields="['lpImageTextUncropped']" :buttons="block.buttons" />
        </section>
        <section
          class="lp__block--blogAndPressReleases__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'blogAndPressReleases'"
        >
          <BlogPressReleases
            :header="block.header"
            :button-link-field-parsed="
              block.blogAndPressReleasesButton.length ? block.blogAndPressReleasesButton[0] : null
            "
            :include-blog-posts="block.includeBlogPosts"
            :include-press-releases="block.includePressReleases"
            :has-override-feed="block.overridePostFeed.length > 0"
            :override-feed="block.overridePostFeed"
            :entry-id="entryId"
            :related-ids="
              getRelatedBlogPressReleaseFeeds(
                block.blogCategories,
                block.pressReleaseCategories,
                block.productCategories
              )
            "
          />
        </section>
        <section class="lp__block--imageCardGrid__wrapper c_wrapper" v-else-if="block.typeHandle === 'imageCardGrid'">
          <LPImageCardGrid
            :alignment="block.alignment"
            :image="block.image"
            :cards="block.cards"
            :card-image-optimized-fields="['lpBackgroundSquare']"
          />
        </section>
        <section class="lp__block--carousel__wrapper c_wrapper" v-else-if="block.typeHandle === 'carousel'">
          <Carousel :images="block.images" :image-optimized-fields="['lpBackgroundWide']" />
        </section>
        <section
          class="lp__block--videoBanner__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'videoBanner' && block.image && block.image.length"
        >
          <VideoBanner
            :image="block.image[0]"
            :image-optimized-fields="['lpBackgroundWide']"
            :background-video="block.backgroundVideo"
            :header="block.header"
            :text="block.text"
          >
          </VideoBanner>
        </section>
        <section
          class="lp__block--linkedImageGrid__wrapper c_wrapper"
          v-else-if="block.typeHandle === 'linkedImageGrid'"
        >
          <Grid class="c_lp_linked_image_grid" :columns="$mq == 'lg' ? 3 : $mq == 'md' ? 2 : 1" gap="20px 10px">
            <LinkField
              class="c_lp_linked_image_grid__item c_box--shadow--center"
              :button-attrs="{ reset: true, unstyle: true }"
              :super-table-field="item"
              v-for="(item, index) in block.images"
              :key="index"
              v-if="parseLinkField(item, 'superTable').valid"
            >
              <ImageInfoOverlay class="c_lp_linked_image_grid__item__overlay">
                <MediaImage
                  class="c_lp_linked_image_grid__image"
                  background
                  :alt="item.image[0] ? item.image[0].title : null"
                  :sources="
                    item.image[0]
                      ? [
                          { srcset: item.image[0].lpImageGridLandscape.srcset.webp, type: 'webp' },
                          { srcset: item.image[0].lpImageGridLandscape.srcset },
                        ]
                      : null
                  "
                />
                <div
                  class="c_lp_linked_image_grid__content"
                  :class="['c_lp_linked_image_grid__content--pos--' + item.alignment, 'c_text--' + item.color]"
                >
                  <ArrowLink :color="item.color" v-if="item.buttonLabel">{{ item.buttonLabel }}</ArrowLink>
                  <div class="c_lp_linked_image_grid__content__description">
                    <p v-if="item.description">{{ item.description }}</p>
                  </div>
                </div>
              </ImageInfoOverlay>
            </LinkField>
          </Grid>
        </section>
        <section
          class="c_wrapper c_wrapper--text"
          v-else-if="block.typeHandle === 'form' && block.form.length && block.form[0].slug != 'print-timeline'"
        >
          <FormEntry :form-slug="block.form[0].slug" />
        </section>
        <section v-else-if="block.typeHandle === 'form' && block.form.length && block.form[0].slug == 'print-timeline'">
          <FormEntry :form-slug="block.form[0].slug" />
        </section>
        <section v-else-if="block.typeHandle === 'relatedProducts' && block.relatedProducts.length">
          <RelatedProducts :header="block.header" :related-entries="block.relatedProducts" />
        </section>
        <section v-else-if="block.typeHandle === 'interactiveDemo' && block.demoEntry.length">
          <InteractiveDemo :slug="block.demoEntry[0].slug" />
        </section>
        <!-- <section v-else-if="block.typeHandle === 'ctaIncludes'">
          <CtaIncludes v-if="block.ctaIncludes" :includes="block.ctaIncludes"> </CtaIncludes>
        </section> -->
        <section v-else-if="block.typeHandle === 'disclaimerFootnote'">
          <DisclaimerFootnote
            v-if="block.disclaimerFootnote"
            :disclaimers="block.disclaimerFootnote"
            theme="grey"
          ></DisclaimerFootnote>
        </section>
        <section v-else-if="block.typeHandle === 'interactiveWebglDemo' && block.demoEntry.length">
          <InteractiveWebglDemo :slug="block.demoEntry[0].slug" />
        </section>
        <section v-else-if="block.typeHandle === 'heroBanner'">
          <HeroBanner
            :banner-size="{
              desktopBannerHeight: block.desktopBannerHeight,
              desktopBannerAspectRatio: block.desktopBannerAspectRatio,
              mobileBannerHeight: block.mobileBannerHeight,
              mobileBannerAspectRatio: block.mobileBannerAspectRatio,
            }"
            :indicator-type="block.indicatorType"
            :autoplay="block.autoplaySlides"
            :duration="block.slideDuration"
            :slides="block.slide"
          />
        </section>
        <section v-else-if="block.typeHandle === 'basicImage'">
          <div
            v-if="block.imageType == 'fullWidth'"
            class="lp__block--image__container"
            :style="'--image-height-restraint:' + block.imageHeightRestraint + ';'"
          >
            <MediaImage
              v-if="block.image[0].lpImageTextUncropped"
              :alt="block.image[0].title"
              :sources="[
                { srcset: block.image[0].lpImageTextUncropped.srcsetWebp, type: 'image/webp' },
                { srcset: block.image[0].lpImageTextUncropped.srcset },
              ]"
            />
          </div>
          <div
            v-else
            class="c_wrapper lp__block--image__container"
            :style="'--image-height-restraint:' + block.imageHeightRestraint + ';'"
          >
            <MediaImage
              v-if="block.image[0].lpImageTextUncropped"
              :alt="block.image[0].title"
              :sources="[
                { srcset: block.image[0].lpImageTextUncropped.srcsetWebp, type: 'image/webp' },
                { srcset: block.image[0].lpImageTextUncropped.srcset },
              ]"
            />
          </div>
          <p class="c_wrapper lp__block--image__caption" v-if="block.caption">
            {{ block.caption }}
          </p>
        </section>
        <section v-else-if="block.typeHandle === 'locationFinder'">
          <LocationFinder :places-url="block.places[0] ? block.places[0].url : ''" />
        </section>
      </div>
    </div>

    <!-- Start: Landing Page 2023 -->
    <div v-if="entryType === 'landingPage2023'">
      <div
        class="lp__block lp__block_2023"
        :class="blockClasses(block, blockIndex)"
        :style="blockStyles(block)"
        v-for="(block, blockIndex) in matrixBlocks"
        :key="blockIndex"
      >
        <Overlay class="c_hero_banner__overlay" ref="videoBannerOverlay" theme="full" :show-close-button="true">
          <div class="c_box--pos--relative c_hero_banner__overlay__video">
            <MediaVideo loading="eager" :source="videoSource" :video-id="videoId"></MediaVideo>
          </div>
        </Overlay>
        <section
          class="c_wrapper c_wrapper--text"
          v-if="block.typeHandle === 'form' && block.form.length && block.form[0].slug != 'print-timeline'"
        >
          <FormEntry :form-slug="block.form[0].slug" />
        </section>
        <section v-if="block.typeHandle === 'form' && block.form.length && block.form[0].slug == 'print-timeline'">
          <FormEntry :form-slug="block.form[0].slug" />
        </section>
        <section
          class="c_wrapper"
          :class="{
            c_grid: block.columns !== '1',
            'lp__block--basicText--2_column': block.columns === '2',
            'lp__block--basicText--3_column': block.columns === '3',
            'c_grid--2': block.columns === '2',
            'c_grid--2-3': block.columns === '3',
          }"
          v-if="block.typeHandle === 'basicText'"
        >
          <LPText
            :button-link-field="parseLinkField(item, 'superTable').valid ? item : null"
            :eyebrow="item.eyebrow || null"
            :eyebrow-style="block.columns !== '1' ? 'regular' : null"
            :subheader="item.subheader || null"
            :text="item.text || null"
            :text-alignment="item.textAlignment || null"
            :primary-cta-btn="item.primaryCtaBtn || null"
            :primary-cta-btn-label="item.primaryCtaBtnLabel || null"
            :primary-cta-btn-aria-label="item.primaryCtaBtnAriaLabel || null"
            :primary-cta-btn-theme="item.primaryCtaBtnTheme || null"
            :primary-cta-btn-open-in-a-new-window="item.primaryCtaBtnOpenInANewWindow || null"
            v-for="(item, index) in block.textBlocks"
            :key="index"
          />
        </section>
        <TextAndImage v-if="block.typeHandle === 'textAndImage'" :data="block" />
        <VideoPlayer
          v-if="block.typeHandle === 'videoPlayer'"
          :video-duration="block.videoDuration"
          :button-text="block.buttonText"
          :page-title="block.pageTitle"
          :image="block.image[0]"
          :image-optimized-fields="['lpBackgroundWide', 'aspectRatio169']"
          :background-video="block.backgroundVideo"
          :header="block.header"
          :text="block.text"
          :display-in-quick-links="block.displayInHeaderQuicklinks"
        />
        <QuoteSlider2023
          v-if="block.typeHandle === 'quoteSlider'"
          :slides="block.slides2"
          text-alignment="center"
          :page-title="block.pageTitle"
        />
        <PageHeader v-if="block.typeHandle === 'pageHeader'" :data="block" />
        <ProductHeader v-if="block.typeHandle === 'productHeader'" :data="block" :anchors="sectionAnchors" />
        <PromoList v-if="block.typeHandle == 'promo' && block.promoEntry.length > 0" :data="block.promoEntry" />
        <TextAndContentCards v-if="block.typeHandle === 'textContentCards'" :data="block" />
        <ProductOverviewGallery v-if="block.typeHandle === 'productOverviewGallery'" :data="block" />
        <VideoGallery :data="block" v-if="block.typeHandle === 'videoGallery'" />
        <RelatedResources :data="block" v-if="block.typeHandle === 'relatedResources'" />
        <Chart :data="block" v-if="block.typeHandle === 'chart'" />
        <FeaturedContent :data="block" v-if="block.typeHandle === 'featuredContent'" />
        <section v-else-if="block.typeHandle === 'interactiveDemo' && block.demoEntry.length">
          <InteractiveDemo :slug="block.demoEntry[0].slug" />
        </section>
        <section v-else-if="block.typeHandle === 'disclaimerFootnote'">
          <DisclaimerFootnote
            v-if="block.disclaimerFootnote"
            :disclaimers="block.disclaimerFootnote"
            theme="grey"
          ></DisclaimerFootnote>
        </section>
        <!-- <section v-else-if="block.typeHandle === 'ctaIncludes'">
          <CtaIncludes v-if="block.ctaIncludes" :includes="block.ctaIncludes"></CtaIncludes>
        </section> -->
        <section v-else-if="block.typeHandle === 'interactiveWebglDemo' && block.demoEntry.length">
          <InteractiveWebglDemo :slug="block.demoEntry[0].slug" />
        </section>
        <section v-else-if="block.typeHandle === 'heroBanner'">
          <HeroBanner
            :banner-size="{
              desktopBannerHeight: block.desktopBannerHeight,
              desktopBannerAspectRatio: block.desktopBannerAspectRatio,
              mobileBannerHeight: block.mobileBannerHeight,
              mobileBannerAspectRatio: block.mobileBannerAspectRatio,
            }"
            :indicator-type="block.indicatorType"
            :autoplay="block.autoplaySlides"
            :duration="block.slideDuration"
            :slides="block.slide"
          />
        </section>
        <CSPromoBlock
          :slides="block.customerStory"
          :block-styling="block.customerStoryStyle"
          :display-title="block.displayTitle"
          v-if="block.typeHandle === 'cSPromoBlock'"
        />
        <LPPromoBlock v-if="block.typeHandle === 'lpPromoBlock'" :blocks="block.promoBlocks" />
        <LandingPageLinks
          v-if="block.typeHandle === 'links'"
          :title="block.linksTitle"
          :content="block.linksContent"
          :extra-top-padding="block.extraTopPadding"
          :extra-bottom-padding="block.extraBottomPadding"
          :links="block.links2"
        />
        <RelatedProductsV2 v-if="block.typeHandle === 'relatedProducts'" :data="block" />
      </div>
    </div>
    <!-- End: Landing Page 2023 -->
  </div>
</template>

<script>
import { parseLinkField, log, slugify } from 'JS/global.js';
import Accordion from 'Components/accordion/Accordion.vue';
import AccordionTab from 'Components/accordion/AccordionTab.vue';
import Button from 'Components/button/Button.vue';
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import LinkField from 'Components/link_field/LinkField.vue';
import MediaImage from 'Components/image/MediaImage.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import DisclaimerFootnote from 'Components/disclaimer_footnote/DisclaimerFootnote.vue';
// import CtaIncludes from 'Components/includes/CtaIncludes.vue';

export default {
  components: {
    Accordion,
    AccordionTab,
    ArrowLink: () => import('Components/arrow_link/ArrowLink.vue'),
    BlogPressReleases: () => import('Components/blog_press_releases/BlogPressReleases.vue'),
    Button,
    Carousel: () => import('Components/carousel/Carousel.vue'),
    FormEntry: () => import('Components/form/FormEntry.vue'),
    Flex: () => import('Components/flex/Flex.vue'),
    Gallery: () => import('Components/gallery/Gallery.vue'),
    GalleryGrid: () => import('Components/gallery/GalleryGrid.vue'),
    GalleryHorizontal: () => import('Components/gallery/GalleryHorizontal.vue'),
    GalleryVideo: () => import('Components/gallery/GalleryVideo.vue'),
    Grid: () => import('Components/grid/Grid.vue'),
    IconSVG,
    ImageInfoOverlay: () => import('Components/image_info_overlay/ImageInfoOverlay.vue'),
    InteractiveDemo: () => import('Components/interactive_demo/InteractiveDemo.vue'),
    InteractiveWebglDemo: () => import('Components/interactive_webgl_demo/InteractiveWebglDemo.vue'),
    LinkField,
    LPTextCardSlider: () => import('Components/lp_text_card_slider/LPTextCardSlider.vue'),
    LPIconText: () => import('Components/lp_icon_text/LPIconText.vue'),
    LPImageCardGrid: () => import('Components/lp_image_card_grid/LPImageCardGrid.vue'),
    LPImageTextTabs: () => import('Components/lp_image_text_tabs/LPImageTextTabs.vue'),
    LPText: () => import('Components/lp_text/LPText.vue'),
    LPTextIconList: () => import('Components/lp_text_icon_list/LPTextIconList.vue'),
    LPTextImageGrid: () => import('Components/lp_text_image_grid/LPTextImageGrid.vue'),
    LPTextList: () => import('Components/lp_text_list/LPTextList.vue'),
    LPTripCards: () => import('Components/lp_trip_cards/LPTripCards.vue'),
    MediaImage,
    MediaVideo,
    // CtaIncludes,
    DisclaimerFootnote,
    Overlay,
    QuoteSlider: () => import('Components/quote_slider/QuoteSlider.vue'),
    RelatedProducts: () => import('Components/related_products/RelatedProducts.vue'),
    SoundCloud: () => import('Components/sound_cloud/SoundCloud.vue'),
    VideoBanner: () => import('Components/video_banner/VideoBanner.vue'),
    HeroBanner: () => import('Components/hero_banner/HeroBanner.vue'),
    LocationFinder: () => import('Components/location_finder/LocationFinder.vue'),
    TextAndImage: () => import('Components/2022_text_and_image/TextAndImage.vue'),
    VideoPlayer: () => import('Components/2022_video_player/VideoPlayer.vue'),
    VideoGallery: () => import('Components/2022_video_gallery/VideoGallery.vue'),
    RelatedResources: () => import('Components/2022_related_resources/RelatedResources.vue'),
    QuoteSlider2023: () => import('Components/2022_quote_slider/QuoteSlider.vue'),
    PageHeader: () => import('Components/2022_page_header/PageHeader.vue'),
    ProductHeader: () => import('Components/2022_product_header/Header.vue'),
    PromoList: () => import('Components/promo/PromoList.vue'),
    TextAndContentCards: () => import('Components/2022_text_and_content_cards/TextAndContentCards.vue'),
    Chart: () => import('Components/2022_chart/Chart.vue'),
    FeaturedContent: () => import('Components/2022_featured_content/FeaturedContent.vue'),
    CSPromoBlock: () => import('Components/2023_promo_block/PromoBlock.vue'),
    LandingPageLinks: () => import('Components/2023_links/LandingPageLinks.vue'),
    LPPromoBlock: () => import('Components/2023_lp_promo_block/LpPromoBlocks.vue'),
    RelatedProductsV2: () => import('Components/2022_related_products/RelatedProducts.vue'),
  },
  metaInfo() {
    return this.seomatic;
  },
  data() {
    return {
      uriBase: '/page/',
      videoId: '',
      videoSource: '',
      previousBlock: null,
      sectionAnchors: [],
    };
  },
  props: {
    entryId: String,
    matrixBlocks: Array,
    matrixBlocks2023: Array,
    relatedPostsIds: Array,
    entryType: String,
  },
  created() {
    this.matrixBlocks.forEach((block) => {
      if (block.pageTitle && block.displayInHeaderQuicklinks) {
        this.sectionAnchors.push({
          title: block.pageTitle,
          anchor: '#' + slugify(block.pageTitle),
        });
      }
    });
  },
  methods: {
    blockClasses(block, blockIndex) {
      const classes = [`lp__block--${block.typeHandle}`];
      let hasBackgroundImage = false;
      // log('block', block);
      let previousBlock = false;
      // log('previous block', this.matrixBlocks[blockIndex - 1]);
      if (this.matrixBlocks[blockIndex - 1]) {
        previousBlock = this.matrixBlocks[blockIndex - 1];
      }

      // Add classes based on block type and style fields
      if (block.colors) {
        switch (block.colors) {
          case 'blackTextGrayBackground':
            classes.push('c_box--gray_235');
            break;
          case 'blackTextGrayBackground240':
            classes.push('c_box--gray_240');
            break;
          case 'blackTextYellowBackground':
            classes.push('c_box--yellow');
            break;
          case 'whiteTextBlackBackground':
            classes.push('c_box--black');
            classes.push('c_text--white');
            break;
          case 'whiteTextDarkGrayBackground':
            classes.push('c_box--gray_41');
            classes.push('c_text--white');
            break;
          default:
            classes.push('c_box--white');
        }
      } else {
        classes.push('c_box--white');
      }
      if (block.layout) {
        classes.push(`lp__block--layout--${block.layout}`);
        switch (block.layout) {
          case 'textImage':
          case 'imageText':
            if (previousBlock && previousBlock.layout) {
              switch (previousBlock.layout) {
                case 'textLargeImage':
                case 'largeImageText':
                case 'textBoxImageBackground':
                case 'imageBackgroundTextBox':
                case 'textImageCard':
                case 'imageTextCard':
                  if (block.colors === previousBlock.colors) {
                    classes.push('lp__block--retain-padding');
                  }
              }
            }
            break;
          case 'textLargeImage':
          case 'largeImageText':
          case 'textBoxImageBackground':
          case 'imageBackgroundTextBox':
          case 'textBoxImageBackgroundFade':
          case 'imageBackgroundFadeTextBox':
          case 'textImageCard':
          case 'imageTextCard':
            classes.push('lp__block--retain-padding');
            break;
        }

        if (['textBoxImageBackgroundFade', 'imageBackgroundFadeTextBox'].includes(block.layout)) {
          hasBackgroundImage = true;
        }
      }
      if (block.backgroundImage || ['quoteSlider', 'videoBanner'].includes(block.typeHandle)) {
        hasBackgroundImage = true;
      }
      if (block.textAlignment) {
        classes.push(`c_text--${block.textAlignment}`);
      }

      if (hasBackgroundImage || ['heroBanner', 'interactiveDemo', 'interactiveWebglDemo'].includes(block.typeHandle)) {
        classes.push('lp__block--background');
      }

      return classes;
    },
    blockStyles(block) {
      const styles = {};

      // Add syles based on block type and style fields
      if (block.colors) {
        switch (block.colors) {
          case 'blackTextGrayBackground':
            styles['--block-bg-color'] = 'var(--color-gray_235)';
            break;
          case 'blackTextYellowBackground':
            styles['--block-bg-color'] = 'var(--color-yellow)';
            break;
          case 'whiteTextBlackBackground':
            styles['--block-bg-color'] = 'var(--color-black)';
            break;
          case 'whiteTextDarkGrayBackground':
            styles['--block-bg-color'] = 'var(--color-gray_41)';
            break;
          default:
            styles['--block-bg-color'] = 'var(--color-white)';
        }
      } else {
        styles['--block-bg-color'] = 'var(--color-white)';
      }
      return styles;
    },
    formatGalleryHorizontalCards(cards) {
      const returnCards = [];
      // log('Cards', cards);
      for (let c = 0; c < cards.length; c++) {
        if (cards[c].image && cards[c].image.length) {
          const returnCard = {
            header: cards[c].header,
            size: 'large',
            text: cards[c].text,
            onImageDescriptor: cards[c].descriptor,
            image: {
              alt: cards[c].image[0].title,
              url: cards[c].image[0].url,
              sources: [
                { srcset: cards[c].image[0].lpGalleryPortrait.srcsetWebp, type: 'image/webp' },
                { srcset: cards[c].image[0].lpGalleryPortrait.srcset },
              ],
            },
          };
          returnCards.push(returnCard);
        }
      }
      return returnCards;
    },
    formatGallerySlides(block) {
      const slides = [];
      let sources = [];
      const images = block.galleryImages;
      // log('images', block.galleryImages);
      for (let i = 0; i < images.length; i++) {
        if (images[i].image && images[i].image.length) {
          const slide = {
            header: images[i].header,
            text: images[i].text,
            button: parseLinkField(images[i], 'superTable').valid ? images[i] : null,
            videoId: images[i].image[0].videoId,
            videoSource: images[i].image[0].videoSource,
            imageTitle: images[i].image[0].title,
          };
          // log('slide initial', slide);
          if (block.overlayContent) {
            // 31.25rem = 500px, matches media query within gallery component.
            sources = [
              {
                media: '(min-width: 31.25rem)',
                srcset: images[i].image[0].lpImageGridLandscape.srcsetWebp,
                type: 'image/webp',
              },
              { media: '(min-width: 31.25rem)', srcset: images[i].image[0].lpImageGridLandscape.srcset },
              { srcset: images[i].image[0].lpGalleryPortrait.srcsetWebp, type: 'image/webp' },
              { srcset: images[i].image[0].lpGalleryPortrait.srcset },
            ];
          } else {
            sources = [
              {
                media: '(min-width: 28.125rem)',
                srcset: images[i].image[0].lpImageGridLandscape.srcsetWebp,
                type: 'image/webp',
              },
              { media: '(min-width: 28.125rem)', srcset: images[i].image[0].lpImageGridLandscape.srcset },
              { srcset: images[i].image[0].lpImageGridSquare.srcsetWebp, type: 'image/webp' },
              { srcset: images[i].image[0].lpImageGridSquare.srcset },
            ];
          }

          slide.sources = sources;
          slides.push(slide);
          // log('slide', slide);
        }
      }
      // log('slides', slides);
      return slides;
    },
    formatTextContentCards(cards, buttons, cardType) {
      const returnCards = [];
      for (let c = 0; c < cards.length; c++) {
        const returnCard = {
          header: cards[c].header,
          descriptor: cards[c].descriptor,
          secondaryDescriptor: cards[c].secondaryDescriptor,
          linkField: cards[c],
          image:
            cards[c].image && cards[c].image.length
              ? {
                  alt: cards[c].image[0].title,
                  url: cards[c].image[0].url,
                }
              : null,
        };
        if (cards[c].image && cards[c].image.length) {
          if (cardType === 'default') {
            returnCard.image.sources = [
              { srcset: cards[c].image[0].lpImageGridLandscape.srcsetWebp, type: 'image/webp' },
              { srcset: cards[c].image[0].lpImageGridLandscape.srcset },
            ];
          } else {
            returnCard.image.sources = [
              { srcset: cards[c].image[0].lpGalleryPortrait.srcsetWebp, type: 'image/webp' },
              { srcset: cards[c].image[0].lpGalleryPortrait.srcset },
            ];
          }
        }
        returnCards.push(returnCard);
      }

      return returnCards;
    },
    formatSecondaryCards(cards) {
      const returnCards = [];
      for (let c = 0; c < cards.length; c++) {
        if (cards[c].image && cards[c].image.length) {
          const returnCard = {
            header: cards[c].header,
            button: cards[c],
            image: {
              title: cards[c].image[0].title,
              url: cards[c].image[0].url,
              sources: [
                { srcset: cards[c].image[0].lpImageGridLandscape.srcsetWebp, type: 'image/webp' },
                { srcset: cards[c].image[0].lpImageGridLandscape.srcset },
              ],
            },
          };
          returnCards.push(returnCard);
        }
      }
      return returnCards;
    },
    galleryGridSources(images) {
      const returnedImages = [];
      for (let i = 0; i < images.length; i++) {
        const returnedImage = {
          title: images[i].title,
          url: images[i].url,
          caption: images[i].caption,
        };
        let sources = [];
        sources = [
          { srcset: images[i].lpImageGridSquare.srcsetWebp, type: 'image/webp' },
          { srcset: images[i].lpImageGridSquare.srcset },
          { srcset: images[i].lpImageGridLandscape.srcsetWebp, type: 'image/webp' },
          { srcset: images[i].lpImageGridLandscape.srcset },
        ];
        returnedImage.sourcesMain = sources;
        returnedImage.sourcesOverlay = [
          { srcset: images[i].lpImageTextUncropped.srcsetWebp, type: 'image/webp' },
          { srcset: images[i].lpImageTextUncropped.srcset },
        ];
        returnedImages.push(returnedImage);
      }
      log('return gallery images', returnedImages);
      return returnedImages;
    },
    iconCardHasButton(iconCards) {
      return iconCards.some((el) => parseLinkField(el, 'superTable').valid);
    },
    iconCardHasText(iconCards) {
      return iconCards.some((el) => el.iconText);
    },
    parseLinkField,
    textImageSources(block) {
      let sources = [];

      switch (block.layout) {
        case 'textImage':
        case 'imageText':
        case 'textImageCard':
        case 'imageTextCard':
          sources = [
            { srcset: block.image[0].lpImageTextUncropped.srcsetWebp, type: 'image/webp' },
            { srcset: block.image[0].lpImageTextUncropped.srcset },
          ];
          break;
        case 'textLargeImage':
        case 'largeImageText':
          sources = [
            { srcset: block.image[0].lpBackgroundSquare.srcsetWebp, type: 'image/webp' },
            { srcset: block.image[0].lpBackgroundSquare.srcset },
          ];
          break;
        case 'textBoxImageBackground':
        case 'imageBackgroundTextBox':
        case 'textBoxImageBackgroundFade':
        case 'imageBackgroundFadeTextBox':
          sources = [
            { media: '(min-width: 1024px)', srcset: block.image[0].lpBackgroundWide.srcsetWebp, type: 'image/webp' },
            { media: '(min-width: 1024px)', srcset: block.image[0].lpBackgroundWide.srcset },
            { srcset: block.image[0].lpBackgroundSquare.srcsetWebp, type: 'image/webp' },
            { srcset: block.image[0].lpBackgroundSquare.srcset },
          ];
          break;
      }

      return sources;
    },
    textImageVideoClick() {
      log('textImageVideoClick');
      log('overlay imageText ref', this.$refs.textImageOverlay);
      if (this.$refs.textImageOverlay && this.$refs.textImageOverlay.length) {
        this.$refs.textImageOverlay[0].show();
      }
    },
    quoteSliderSlides(slides) {
      const returnSlides = [];
      for (let s = 0; s < slides.length; s++) {
        const slide = {
          eyebrow: slides[s].eyebrow,
          quote: slides[s].quote,
          author: slides[s].quoteAuthor,
          descriptor: slides[s].descriptor,
          imagePosition: slides[s].imagePosition,
        };
        if (slides[s].image[0]) {
          slide.image = {
            title: slides[s].image[0].title,
            sources: [
              { srcset: slides[s].image[0].lpBackgroundWide.srcsetWebp, type: 'image/webp' },
              { srcset: slides[s].image[0].lpBackgroundWide.srcset },
            ],
          };
        }
        returnSlides.push(slide);
      }
      return returnSlides;
    },
    getRelatedBlogPressReleaseFeeds(blogCategories, pressReleaseCategories, productCategories) {
      // Get Related Blog & Press Release Category IDS and return them to the Blog Press Release Widget as relatedIds.
      const categoryFeeds = [];

      for (const i in blogCategories) {
        categoryFeeds.push(blogCategories[i].id);
        log('Adding:' + blogCategories[i].title);
      }
      for (const i in pressReleaseCategories) {
        categoryFeeds.push(pressReleaseCategories[i].id);
        log('Adding:' + pressReleaseCategories[i].title);
      }
      for (const i in productCategories) {
        categoryFeeds.push(productCategories[i].id);
        log('Adding:' + productCategories[i].title);
      }

      return categoryFeeds;
    },
    doesBlogPressReleaseHaveOverrideFeed(overridePostFeed) {
      // Send True or False variable based on length of
      if (overridePostFeed.length) {
        //
      }
    },
    // videoPlayerHasVideos: function(tabs) {
    //   log('video Player Has Videos Fired');
    //   let videoArray = [];
    //   for (let t = 0; t < tabs.length; t++) {
    //     let video = tabs[t].videos.filter(function(obj) {
    //       if (obj.videoId && obj.videoId.length) {
    //         return obj;
    //       }
    //     });
    //     if (video && video.length) {
    //       videoArray.push(video);
    //     }
    //   }
    //   log('videoPlayerArray', videoArray);
    //   if (videoArray && videoArray.length) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    toggleVideo(videoId, videoSource) {
      this.videoId = videoId;
      this.videoSource = videoSource;
      this.$nextTick(() => {
        this.$refs.videoBannerOverlay[0]?.show();
      });
      event.preventDefault();
    },
  },
  mounted() {
    const component = this;
    this.$refs?.lp_block?.addEventListener('click', function (event) {
      if (!event.srcElement?.href) return;
      const url = event.srcElement?.href;
      // eslint-disable-next-line
      const regex =
        /^.*(?:(?:youtu\.be\/|youtube\.com\/(?:embed|v(?:i)?|e)\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/;
      const n = url.match(regex);
      if (n) {
        component.toggleVideo(n[1], 'youtube');
      }
      if (url.includes('youku')) {
        const id = url.split('id_')[1].slice(0, 13);
        component.toggleVideo(id, 'youku');
      }
    });
  },
};
</script>

<style lang="scss">
.lp__block {
  $self: &;
  --lp-block-min-height: 400px;

  margin: var(--lp-block-margin-top, var(--lp-block-margin, 0)) 0
    var(--lp-block-margin-bottom, var(--lp-block-margin, 0));
  padding: var(--lp-block-padding-top, var(--lp-block-padding, 0)) 0
    var(--lp-block-padding-bottom, var(--lp-block-padding, 0));
  position: relative;

  // @at-root #{$self}.c_box--black:not(.lp__block--background):not(.lp__block--layout--textBoxImageBackgroundFade):not(.lp__block--layout--imageBackgroundFadeTextBox)
  //     + #{$self}.c_box--black:not(.lp__block--retain-padding),
  @at-root #{$self}.c_box--white:not(.lp__block--background):not(.lp__block--layout--textBoxImageBackgroundFade):not(.lp__block--layout--imageBackgroundFadeTextBox)
      + #{$self}.c_box--white:not(.lp__block--retain-padding),
    #{$self}.c_box--yellow:not(.lp__block--background):not(.lp__block--layout--textBoxImageBackgroundFade):not(.lp__block--layout--imageBackgroundFadeTextBox)
      + #{$self}.c_box--yellow:not(.lp__block--retain-padding),
    #{$self}.c_box--gray_235:not(.lp__block--background):not(.lp__block--layout--textBoxImageBackgroundFade):not(.lp__block--layout--imageBackgroundFadeTextBox)
      + #{$self}.c_box--gray_235:not(.lp__block--retain-padding),
    #{$self}.c_box--gray_41:not(.lp__block--background):not(.lp__block--layout--textBoxImageBackgroundFade):not(.lp__block--layout--imageBackgroundFadeTextBox)
      + #{$self}.c_box--gray_41:not(.lp__block--retain-padding) {
    --lp-block-padding-top: 0;
  }

  @at-root .c_hero_banner__overlay {
    @at-root .c_hero_banner__overlay__video {
      width: 90vw;
      max-width: 100%;
      overflow: hidden;
      @include mq($mq_sm_to_md) {
        width: 70vw;
      }
      @include mq($mq_md_to_lg) {
        width: px_to_rem(800);
      }
    }
  }
  @at-root #{$self}--pageHeader {
    $pageHeader: &;
    --lp-block-padding: 3rem;

    @at-root #{&}__wrapper {
      position: relative;
      z-index: 1;

      @at-root #{$pageHeader}--background {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: center;
        min-height: var(--lp-block-min-height);
      }
    }
  }
  @at-root #{$self}--basicText {
    --lp-block-padding: 5rem;

    @at-root #{&}--2_column,
      #{&}--3_column {
      --subheader-fz: 1.9rem;
    }
    @at-root #{&}--2_column {
      --grid-gap: 50px;
    }
    @at-root #{&}--3_column {
      --grid-gap: 30px;
    }
    &.lp__block_2023 {
      @include font('graphik_regular');
      h2 {
        @include fs_lh(32, 40, 24, 30);
        @include font('graphik_semibold');
        margin-bottom: px_to_rem(19);
        @include mq($mq_sm_to_md) {
          margin-bottom: px_to_rem(23);
        }
      }
      p,
      strong,
      li,
      em {
        @include fs_lh(18, 22.5, 16, 20);
      }
      .c_lp_text__eyebrow {
        @include fs_lh(16, 21, 14, 18);
        @include font('graphik_medium');
        opacity: 1;
        margin-bottom: px_to_rem(5);
      }
      .c_lp_text {
        max-width: 100%;
      }
    }
  }
  @at-root #{$self}--textImage {
    $textImage: &;
    $__mq_two_columns: 900;
    $__textImage_columns: 50% minmax(200px, 500px);
    $__textImage_columns_reversed: minmax(200px, 500px) 50%;

    --lp-block-padding: 5rem;

    @include mq($__mq_two_columns) {
      --lp-block-min-height: 400px;
    }

    @include mq_max($__mq_two_columns) {
      --lp-block-padding-top: 0;
    }

    @at-root #{$self}--layout--textImageCard,
      #{$self}--layout--imageTextCard {
      --lp-block-padding: 100px;
      background-color: $color_white;
    }

    @at-root #{&}__button {
      position: relative;
      &:hover,
      &:focus {
        #{$textImage}__image__play_icon {
          color: $color_yellow;
        }
      }
      @include mq_max($__mq_two_columns) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vw;
        #{$textImage}__image img {
          height: 100vw;
        }
      }
    }
    @at-root #{&}__image {
      $image: &;
      @at-root #{&}__play_icon {
        color: $color_white;
        z-index: 20;
      }
      @at-root #{&}__mask {
        background: rgba($color_black_raw, 0.4);
      }
    }
    @at-root #{&}__wrapper {
      @include mq_max($__mq_two_columns) {
        max-width: 600px;
      }

      @at-root #{$self}--layout--textImage &,
        #{$self}--layout--imageText & {
        display: grid;
        grid-template-rows: 100vw auto;
        grid-gap: 40px;
        align-items: center;

        #{$textImage}__image {
          @include mq_max($__mq_two_columns) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100vw;
          }
        }

        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }

        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
            @include mq($__mq_two_columns) {
              grid-column: 2;
              grid-row: 1;
            }
          }
        }

        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns;
          grid-template-rows: auto;
          grid-gap: 85px;
        }
      }
      @at-root #{$self}--layout--textImage & {
        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }
        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
          }
        }
        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns_reversed;
          & > *:first-child {
            order: 1;
            @at-root .app--isEdge15orDown & {
              grid-column: 2;
              grid-row: 1;
            }
          }
          #{$textImage}__text {
            @at-root .app--isEdge15orDown & {
              grid-column: 1;
              grid-row: 1;
            }
          }
        }
      }
      @at-root #{$self}--layout--textLargeImage &,
        #{$self}--layout--largeImageText & {
        display: grid;
        grid-template-rows: 100vw auto;
        grid-gap: 40px;
        align-items: center;
        min-height: var(--lp-block-min-height);

        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }

        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
            @include mq($__mq_two_columns) {
              grid-column: 2;
              grid-row: 1;
            }
          }
        }

        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns;
          grid-template-rows: auto;
          grid-gap: 85px;
        }
      }
      @at-root #{$self}--layout--textLargeImage & {
        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }
        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
          }
        }
        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns_reversed;
          & > *:first-child {
            order: 1;
            @at-root .app--isEdge15orDown & {
              grid-column: 2;
              grid-row: 1;
            }
          }
          #{$textImage}__text {
            @at-root .app--isEdge15orDown & {
              grid-column: 1;
              grid-row: 1;
            }
          }
        }

        #{$textImage}__image {
          position: absolute;
          top: 0;
          height: 100vw;

          @include mq($__mq_two_columns) {
            right: 0;
            left: auto;
            width: 50%;
            height: 100%;
          }
        }
      }
      @at-root #{$self}--layout--largeImageText & {
        #{$textImage}__image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100vw;

          @include mq($__mq_two_columns) {
            width: 50%;
            height: 100%;
          }
        }
      }
      @at-root #{$self}--layout--imageBackgroundTextBox &,
        #{$self}--layout--textBoxImageBackground &,
        #{$self}--layout--textBoxImageBackgroundFade &,
        #{$self}--layout--imageBackgroundFadeTextBox & {
        display: grid;
        grid-gap: 40px;
        align-items: center;

        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns;
          grid-gap: 85px;
        }

        #{$textImage}__text {
          position: relative;
          max-width: 470px;
          z-index: 2;
        }
        #{$textImage}__image {
          @include mq($__mq_two_columns) {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      @at-root #{$self}--layout--imageBackgroundTextBox &,
        #{$self}--layout--textBoxImageBackground & {
        @include mq_max($__mq_two_columns) {
          #{$textImage}__image {
            @include box_break_out;
            height: 400px;
          }
        }
        @include mq($__mq_two_columns) {
          #{$textImage}__text {
            padding: 30px;
            background-color: var(--block-bg-color);
            border-radius: $border_radius;
            box-sizing: border-box;
            z-index: 1;
            @at-root .app--isEdge15orDown & {
              grid-column: 2;
              grid-row: 1;
            }
          }
        }
      }
      @at-root #{$self}--layout--textBoxImageBackgroundFade &,
        #{$self}--layout--imageBackgroundFadeTextBox & {
        min-height: 450px;

        #{$textImage}__text {
          --subheader-fz: 2.9rem;
          --text-size: 1.15rem !important;
          @at-root .app--isEdge15orDown & {
            grid-column: 2;
            grid-row: 1;
          }
        }
      }
      @at-root #{$self}--layout--textBoxImageBackground &,
        #{$self}--layout--textBoxImageBackgroundFade & {
        @include mq($__mq_two_columns) {
          grid-template-columns: $__textImage_columns_reversed;

          #{$textImage}__text {
            order: -1;
            @at-root .app--isEdge15orDown & {
              grid-column: 1;
              grid-row: 1;
            }
          }
        }
      }
      @at-root #{$self}--layout--textImageCard &,
        #{$self}--layout--imageTextCard & {
        display: grid;
        grid-template-rows: 100vw auto;
        align-items: center;
        position: relative;
        padding: 0;
        background-color: var(--block-bg-color);
        box-shadow: 0 5px 5px $color_shadow;

        @include mq(1050px) {
          grid-template-rows: px_to_rem(460);
        }

        #{$textImage}__text {
          --subheader-fz: 2.9rem;
          --text-size: 1.2rem !important;
          padding: 2rem 5rem 2rem 3rem;
        }
      }
      @at-root #{$self}--layout--textImageCard & {
        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
          }
        }
        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }
        @include mq($__mq_two_columns) {
          grid-template-columns: 1fr px_to_rem(460);

          #{$textImage}__text {
            order: -1;
            @at-root .app--isEdge15orDown & {
              grid-column: 1;
              grid-row: 1;
            }
          }
          & > *:first-child {
            @at-root .app--isEdge15orDown & {
              grid-column: 2;
              grid-row: 1;
            }
          }
        }
      }
      @at-root #{$self}--layout--imageTextCard & {
        #{$textImage}__text {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 2;
          }
        }
        & > *:first-child {
          @at-root .app--isEdge15orDown & {
            grid-column: 1;
            grid-row: 1;
          }
        }
        @include mq($__mq_two_columns) {
          grid-template-columns: px_to_rem(460) 1fr;
          #{$textImage}__text {
            @at-root .app--isEdge15orDown & {
              grid-column: 2;
              grid-row: 1;
            }
          }
          & > *:first-child {
            @at-root .app--isEdge15orDown & {
              grid-column: 1;
              grid-row: 1;
            }
          }
        }
      }

      @at-root #{$textImage}__fade {
        --fade-direction: to left;
        background-image: linear-gradient(
          var(--fade-direction),
          $color_black_raw,
          rgba($color_black_raw, var(--backgroundOpacity)) 30%
        );

        @at-root #{$self}--layout--textBoxImageBackgroundFade & {
          --fade-direction: to right;
        }

        @include mq_max($__mq_two_columns) {
          display: block;
          z-index: 1;
        }
      }
      @at-root #{$textImage}__buttons {
        position: absolute;
        left: 0%;
        bottom: -60px;
        width: 100%;

        @include mq($__mq_two_columns) {
          bottom: -90px;
        }
      }
      @at-root #{$textImage}__overlay {
        @at-root #{&}__video {
          width: 90vw;
          max-width: 100%;
          overflow: hidden;
          @include mq($mq_sm_to_md) {
            width: 70vw;
          }
          @include mq($mq_md_to_lg) {
            width: px_to_rem(800);
          }
        }
      }
    }

    @at-root #{$self}__background {
      position: absolute;
    }
    @at-root #{$self}--iconCards {
      --lp-block-padding: #{px_to_rem(120)};
      @at-root #{&}__grid {
        @at-root #{&}__item {
          --lp-icon-text--border-radius: 0;
        }
      }
    }
    @at-root #{$self}--contentIconCards {
      $contentCards: &;
      --lp-block-padding-top: #{px_to_rem(60)};
      --lp-block-padding-bottom: #{px_to_rem(80)};
      @at-root #{&}__wrapper {
        display: grid;
        grid-gap: px_to_rem(40);
        grid-template-columns: 1fr;
        align-items: center;

        @at-root .app--isEdge15orDown & {
          display: flex;
          flex-flow: column nowrap;
        }
        @at-root #{&}--layout {
          $layout: &;
          @at-root #{&}--horizontal {
            @include mq($mq_md_to_lg) {
              grid-gap: px_to_rem(100);
              grid-template-columns: repeat(2, 1fr);
            }

            @at-root & #{$contentCards}__grid {
              $grid: &;
              display: grid;
              grid-gap: px_to_rem(25);
              grid-template-columns: 1fr;
              @at-root .app--isEdge15orDown & {
                display: flex;
                flex-flow: column wrap;
              }

              @include mq($mq_sm_to_md) {
                grid-template-columns: repeat(2, 1fr);

                @at-root .app--isEdge15orDown & {
                  flex-flow: row wrap;
                }
              }

              @include mq(900) {
                //grid-template-columns: repeat(4, 1fr);
                @at-root #{&}--count--3 {
                  z-index: 3;
                  //grid-template-columns: repeat(3, 1fr);
                  @at-root #{&} #{$grid}__item:last-child {
                    grid-column: auto;
                  }
                }
              }

              @include mq($mq_md_to_lg) {
                grid-template-columns: repeat(2, 1fr);
                @at-root #{&}--count--3 {
                  @at-root #{&} #{$grid}__item:last-child {
                    grid-column: auto;
                  }
                }
              }

              #{&}__item {
                --lp-icon-text--icon: #{px_to_rem(50)};
                --lp-icon-text--header--margin-bottom: 0;
                --lp-icon-text--padding: #{px_to_rem(40)};
                color: $color_black;
              }
            }
          }
          /* @at-root #{&}--contentTop {
          } */
          @at-root #{&}--contentLeft {
            @include mq($mq_md_to_lg) {
              #{$contentCards}__content {
                grid-column: 1;
                grid-row: 1;
              }
              @at-root #{&} #{$contentCards}__grid {
                @at-root #{&}--count--3 {
                  .lp__block--contentIconCards__grid__item:last-child {
                    z-index: 33;
                    grid-column: 1/3;
                  }
                }
              }
            }
          }
          @at-root #{&}--contentRight {
            @include mq($mq_md_to_lg) {
              #{$contentCards}__content {
                grid-column: 2;
                grid-row: 1;
              }
              #{$contentCards}__grid {
                grid-column: 1;
                grid-row: 1;
                @at-root #{&}--count--3 {
                  .lp__block--contentIconCards__grid__item:last-child {
                    z-index: 33;
                    grid-column: 1/3;
                  }
                }
              }
            }
          }
        }
      }

      @at-root #{&}__grid {
        --content-icon-cards--grid-repeat: 1;
        --content-icon-cards--grid-width: 1fr;
        --content-icon-cards--grid-gap: #{px_to_rem(25)};

        $grid: &;
        display: grid;
        grid-gap: var(--content-icon-cards--grid-gap);
        justify-content: center;
        grid-template-columns: repeat(var(--content-icon-cards--grid-repeat), var(--content-icon-cards--grid-width));
        @at-root .app--isEdge15orDown & {
          display: flex;
          flex-flow: column nowrap;
        }

        @include mq($mq_sm_to_md) {
          --content-icon-cards--grid-repeat: 2;
          @at-root #{$grid}--count--1 {
            --content-icon-cards--grid-repeat: 1;
            --content-icon-cards--grid-width: 50%;
          }
          @at-root .app--isEdge15orDown & {
            flex-flow: row wrap;
            & > * {
              flex: 0 1 50%;
            }
          }
        }
        @include mq($mq_md_to_lg) {
          --content-icon-cards--grid-repeat: 1;
          --content-icon-cards--grid-width: calc(25% - 2vw);
          @at-root .app--isEdge15orDown & {
            & > * {
              flex: 0 1 25%;
            }
          }
        }

        @at-root #{&}--count {
          @include mq($mq_sm_to_md) {
            @at-root #{&}--3 {
              @at-root #{&} #{$grid}__item:last-child {
                grid-column: 1/3;
              }
            }
          }

          @include mq($mq_md_to_lg) {
            @at-root #{&}--2 {
              --content-icon-cards--grid-repeat: 2;
              @at-root .app--isEdge15orDown & {
                & > * {
                  flex: 0 1 50%;
                }
              }
            }
            @at-root #{&}--3 {
              --content-icon-cards--grid-repeat: 3;
              @at-root .app--isEdge15orDown & {
                & > * {
                  flex: 0 1 33%;
                }
              }
              @at-root #{&} #{$grid}__item:last-child {
                grid-column: auto;
              }
            }
            @at-root #{&}--4 {
              --content-icon-cards--grid-repeat: 4;
              @at-root .app--isEdge15orDown & {
                & > * {
                  flex: 0 1 25%;
                }
              }
            }
          }
        }
        @at-root #{&}__item {
          $item: &;
          --lp-icon-text--icon: #{px_to_rem(60)};
          color: $color_black;
          img {
            max-height: 100%;
          }

          @at-root #{$grid}--has_text {
            #{$item} {
              --lp-icon-text--icon: #{px_to_rem(60)};
              --lp-icon-text--header--font-size: #{px_to_rem(20)};
              --lp-icon-text--header--margin-bottom: #{px_to_rem(5)};
              --lp-icon-text--padding: #{px_to_rem(40)};
            }
          }
          @at-root #{$grid}--has_button {
            #{$item} {
              --lp-icon-text--icon: #{px_to_rem(40)};
              --lp-icon-text--padding: #{px_to_rem(40)} #{px_to_rem(40)} #{px_to_rem(30)};
              --lp-icon-text--header--font-size: #{px_to_rem(20)};
              --lp-icon-text--header--margin-bottom: 0;
            }
          }
        }
      }
    }
    @at-root #{$self}--textIconCards {
      --lp-block-padding: #{px_to_rem(80)};
      @at-root #{&}__wrapper {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: px_to_rem(40);
        align-items: center;

        @at-root .app--isEdge15orDown & {
          display: flex;
          flex-flow: column nowrap;
        }
        @include mq($mq_md_to_lg) {
          grid-gap: px_to_rem(100);
          grid-template-columns: repeat(2, 1fr);
          @at-root .app--isEdge15orDown & {
            flex-flow: row wrap;
            & > * {
              flex: 0 1 50%;
            }
          }
        }
      }
      @at-root #{&}__grid {
        display: grid;
        grid-gap: px_to_rem(25);
        grid-template-columns: 1fr;

        @at-root .app--isEdge15orDown & {
          display: flex;
          flex-flow: column nowrap;
        }
        @include mq(500) {
          grid-template-columns: repeat(2, 1fr);
          @at-root .app--isEdge15orDown & {
            flex-flow: row wrap;
            & > * {
              flex: 0 1 50%;
            }
          }
        }

        @include mq(900) {
          grid-template-columns: repeat(4, 1fr);
          @at-root .app--isEdge15orDown & {
            & > * {
              flex: 0 1 25%;
            }
          }
        }

        @include mq($mq_md_to_lg) {
          grid-template-columns: repeat(2, 1fr);
          @at-root .app--isEdge15orDown & {
            & > * {
              flex: 0 1 50%;
            }
          }
        }

        @at-root #{&}__item {
          --lp-icon-text--icon: #{px_to_rem(50)};
          --lp-icon-text--header--margin-bottom: 0;
          --lp-icon-text--padding: #{px_to_rem(40)};
          background: $color_yellow;
          color: $color_black;
          @at-root #{&}--white {
            background: $color_white;
          }
        }
      }
    }
    @at-root #{$self}--gallery {
      --lp-block-padding: #{px_to_rem(60)};
    }
    @at-root #{$self}--galleryGrid {
      --lp-block-padding: #{px_to_rem(125)};
    }
    @at-root #{$self}--embedSoundcloud {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--textContentCards {
      --lp-block-padding: #{px_to_rem(40)};
    }
    @at-root #{$self}--textIconList {
      --lp-block-padding: #{px_to_rem(40)};
    }
    @at-root #{$self}--textList {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--featuredCardCards {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--imageTextTabs {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--imageCardGrid {
      --lp-block-padding: #{px_to_rem(95)};
    }
    @at-root #{$self}--blogAndPressReleases {
      --lp-block-padding: #{px_to_rem(70)};
    }
    @at-root #{$self}--carousel {
      --lp-block-padding: #{px_to_rem(95)};
    }
    @at-root #{$self}--linkedImageGrid {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--videoPlayer {
      --lp-block-padding: #{px_to_rem(0)};
    }
    @at-root #{$self}--textAccordion {
      $textAccordion: &;
      --lp-block-padding: 5rem;
      --grid-gap: #{px_to_rem(40)};

      @at-root #{&}__tab {
        $tab: &;
        & + & {
          margin-top: 12px;
        }
        .c_accordion_tab__header {
          &:hover,
          &:focus {
            #{$textAccordion}__header {
              background: $color_black;
              color: $color_white;
            }
          }
        }
      }

      @at-root #{&}__header {
        display: grid;
        grid-template-columns: 12px 1fr;
        align-items: center;
        grid-gap: 0.9rem;
        padding: 0.7rem 0.8rem 0.6rem;
        border: 1px solid $color_gray_191;
        border-radius: $border_radius;
        text-transform: uppercase;
        @include font('graphik_bold');
        font-size: 0.75rem;

        @at-root .c_accordion_tab--open & {
          background-color: $color_yellow;
          border-color: transparent;
        }

        @at-root #{&}__icon {
          grid-column: 1;
          grid-row: 1;
          @at-root #{&}--plus {
            display: inline-flex;

            @at-root .c_accordion_tab--open & {
              display: none;
            }
          }
          @at-root #{&}--minus {
            display: none;

            @at-root .c_accordion_tab--open & {
              display: inline-flex;
            }
          }
        }
        @at-root #{&}__label {
          display: inline-block;
          grid-column: 2;
          grid-row: 1;
        }
      }
      @at-root #{&}__content {
        margin-top: 0.9rem;
        color: $color_gray_112;
      }
    }
    @at-root #{$self}--form {
      --lp-block-padding: #{px_to_rem(80)};
    }
    @at-root #{$self}--interactiveDemo {
      z-index: 50;
    }
    /* @at-root #{$self}*/
  }
  @at-root #{$self}--image__container {
    text-align: center;
    img {
      width: auto;
      max-height: var(--image-height-restraint);
    }
  }
  @at-root #{$self}--image__caption {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @at-root #{&}_2023 {
    &.lp__block--form {
      padding-top: px_to_rem(35);
      padding-bottom: px_to_rem(35);
      @include mq($mq_sm_to_md) {
        padding-top: px_to_rem(80);
        padding-bottom: px_to_rem(80);
      }
    }
  }
}
</style>
